<template>
<div>
    <CNavbar expandable="md" class="bg-nav">
        <CToggler in-navbar @click="collapsed = !collapsed" />
        <CHeaderBrand class="mx-auto d-lg-none">
            <router-link :to="{name: 'Panel de Control'}" class="router-link-none">
                <img :src="datoSession.logo" class="img-fluid mt-0 mb-0" width="150px">
            </router-link>
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
            <router-link :to="{name: 'Panel de Control'}" class="router-link-none">
                <img :src="datoSession.logo" class="c-sidebar-brand-full img-fluid mt-2 mb-2" width="150px">
            </router-link>
        </CHeaderNav>
        <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
                <div>
                    <b-dropdown size="lg" v-if="checkPermissions('001-DOC','r') == 1" :class="currentRouteName == 'Documentacion' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class=" dropdown-icon"> <i v-tooltip.top="{content: 'DOCUMENTACIÓN'}" style="inline-size: auto" class="fas fa-file-alt fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('001-DOC-DEC','r') == 1" :to="{name:'Cargar y descargar'}">Cargar y descargar documentos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('001-DOC-BAN','r') == 1" :to="{name:'Banco de normas'}">Banco de normas</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('002-PRO','r') == 1" :class="currentRouteName == 'Procesos y objetivos' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">

                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'PROCESOS Y OBJETIVOS'}" style="inline-size: auto" class="fas fa-flag fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('002-PRO-MAP','r') == 1" :to="{name:'Mapa de procesos'}">Mapa de procesos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('002-PRO-OBJ','r') == 1" :to="{name:'Objetivos'}">Objetivos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('002-PRO-IND','r') == 1" :to="{name:'Indicadores'}">Listado de indicadores</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('003-RIE','r') == 1" :class="currentRouteName == 'Riesgos' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'RIESGOS'}" style="inline-size: auto" class="fas fa-exclamation-triangle fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('003-RIE-FOD','r') == 1" :to="{name: 'FODA'}">FODA</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('003-RIE-CON','r') == 1" :to="{name:'Contexto'}">Contexto</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('003-RIE-INF','r') == 1" :to="{name: 'Informes'}">Informes</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('003-RIE-GES','r') == 1" :to="{name: 'Gestión de riesgos'}">Gestión de riesgos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('003-RIE-EVA','r') == 1" :to="{name: 'Evaluación de riesgos'}">Evaluación de riesgos</b-dropdown-item>
                    </b-dropdown>
                    <!-- v-if="this.$store.state.modules.SGMA == 1"  -->
                    <!-- v-if="checkPermissions('0031-EMP','r') == 1 && this.$store.state.modules.length>0 && this.$store.state.modules.filter(x => x.identificador == 'EMPS')[0].isActivo == 2" -->
                    <b-dropdown size="lg" v-if="checkPermissions('016-VCM','r') == 1 && !!this.$store.state.modules.find(x => x.identificador == 'VCM')" :class="currentRouteName == 'Empresa segura' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'EMPRESA SEGURA'}" style="inline-size: auto" class="fas fa-business-time fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('016-VCM-GEI','r') == 1" :to="{name: 'Gestión inicial empresa segura'}">Gestión inicial</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('016-VCM-INC','r') == 1" :to="{name: 'Informe a la comunidad'}">Informes a la comunidad</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('016-VCM-VID','r') == 1" :to="{name: 'Videos sensiblización'}">Videos de sensiblización</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown v-if="checkPermissions('004-14001','r') == 1 &&  !!this.$store.state.modules.find(x => x.identificador == 'SGMA')" size="lg" :class="currentRouteName == 'ISO 14001' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'ISO 14001'}" style="inline-size: auto" class="fas fa-leaf fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('004-14001-GEI','r') == 1" :to="{name: 'Gestión inicial 14001'}">Gestión inicial</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-EVA','r') == 1" :to="{name: 'Evaluación 14001'}">Evaluación</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-GES','r') == 1" :to="{name: 'Gestiones 14001'}">Gestiones</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-INF','r') == 1" :to="{name: 'Informes 14001'}">Informes</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-REA','r') == 1" :to="{name: 'Requisitos medioambientales 14001'}">Requisitos Medioambientales</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-SIM','r') == 1" :to="{name: 'Simulacros'}">Simulacros</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-COM','r') == 1" :to="{name: 'Comunicaciones ambientales'}">Comunicaciones Ambientales</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('004-14001-INV','r') == 1" :to="{name: 'Inventario residuos'}">Inventario Residuos</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown v-if="checkPermissions('005-27001','r') == 1 && !!this.$store.state.modules.find(x => x.identificador == 'SGSI')" size="lg" :class="currentRouteName == 'ISO 27001' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'ISO 27001'}" style="inline-size: auto" class="fas fa-shield-alt fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('005-27001-DEC','r') == 1" :to="{name: 'Declaración de aplicabilidad'}">Declaración de Aplicabilidad</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('005-27001-ACT','r') == 1" :to="{name: 'Activos de información'}">Activos de información</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('005-27001-VUL','r') == 1" :to="{name: 'Vulnerabilidades'}">Vulnerabilidades y gestión de riesgos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('005-27001-POL','r') == 1" :to="{name: 'Políticas'}">Políticas</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown v-if="checkPermissions('006-45001','r') == 1 &&  !!this.$store.state.modules.find(x => x.identificador == 'SST') " size="lg" :class="currentRouteName == 'ISO 45001' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'ISO 45001'}" style="inline-size: auto" class="fas fa-medkit fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('006-45001-INF','r') == 1" :to="{name: 'Informes 45001'}"> Informes</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown v-if="checkPermissions('007-9001','r') == 1 && !!this.$store.state.modules.find(x => x.identificador == 'SGC') " size="lg" :class="currentRouteName == 'ISO 9001' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'ISO 9001'}" style="inline-size: auto" class="fas fa-award fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('007-9001-ASE','r') == 1" :to="{name: 'Aseguramiento de calidad'}">Aseguramiento de calidad</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown v-if="checkPermissions('017-37001','r') == 1 && !!this.$store.state.modules.find(x => x.identificador == 'SGA') " size="lg" :class="currentRouteName == 'ISO 37001' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'ISO 37001'}" style="inline-size: auto" class="fas fa-handshake fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('017-37001-PPO','r') == 1" :to="{name: 'Políticas, normas y códigos antisoborno'}">1. Políticas, normas y códigos antisoborno</b-dropdown-item>
                        <!-- <b-dropdown-item v-if="checkPermissions('017-37001-PNO','r') == 1" :to="{name: 'Publicación de normas'}">Publicación de normas</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-PCO','r') == 1" :to="{name: 'Publicación de códigos antisoborno y nombramiento'}">Publicación de códigos antisoborno y nombramiento</b-dropdown-item> -->
                        <b-dropdown-item v-if="checkPermissions('017-37001-SON','r') == 1" :to="{name: 'Socios de negocio'}">2. Socios de negocio</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-ERA','r') == 1" :to="{name: 'Evaluación de riesgos antisoborno'}">3. Evaluaciones riesgos antisoborno</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-COA','r') == 1" :to="{name: 'Controles antisoborno'}">4. Controles antisoborno</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-IQA','r') == 1" :to="{name: 'Inquietudes antisoborno'}">5. Inquietudes antisoborno</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-GSA','r') == 1" :to="{name: 'Gestión y seguimiento de casos antisoborno'}">6. Gestión y seguimiento de casos antisoborno</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-AFC','r') == 1" :to="{name: 'Actas función de cumplimiento'}">7. Actas función de cumplimiento - órgano de gobierno</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-DTD','r') == 1" :to="{name: 'Delegación de toma de decisiones'}">8. Delegación de toma de decisiones</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('017-37001-PSA','r') == 1" :to="{name: 'Puestos sensibles antisoborno'}">9. Puestos sensibles antisoborno</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('008-PRO','r') == 1" :class="currentRouteName == 'Proyectos' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'PROYECTOS'}" style="inline-size: auto" class="fas fa-cubes fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('008-PRO-CLI','r') == 1" :to="{name: 'Lista de Clientes'}">Clientes</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('008-PRO-PRO','r') == 1" :to="{name: 'Lista de Proyectos'}">Proyectos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('008-PRO-LDP','r') == 1" :to="{name: 'Lote de productos'}">Lote de productos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('008-PRO-SER','r') == 1" :to="{name: 'Servicios coordinador'}">Servicios</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('009-REC','r') == 1" :class="currentRouteName == 'Recursos' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'RECURSOS'}" style="inline-size: auto" class="fas fa-laptop fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('009-REC-PRO','r') == 1" :to="{name: 'Proveedores'}">Proveedores</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('009-REC-EQU','r') == 1" :to="{name: 'Equipos Medicion'}">Equipos de medición</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('009-REC-REC','r') == 1" :to="{name: 'Recursos humanos'}">Recursos humanos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('009-REC-INF','r') == 1" :to="{name: 'Infraestructura'}">Infraestructura</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('010-MEJ','r') == 1" :class="currentRouteName == 'Mejora continua' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'MEJORA CONTINUA'}" style="inline-size: auto" class="fas fa-chart-line fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('010-MEJ-OTR','r') == 1" :to="{name: 'Otros Documentos'}">Otros documentos</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('010-MEJ-SAT','r') == 1" :to="{name: 'Satisfaccion Cliente'}">Satisfacción de clientes</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('010-MEJ-REV','r') == 1" :to="{name: 'Revisión por la dirección'}">Revisión por la dirección</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('010-MEJ-AUD','r') == 1" :to="{name: 'Auditoría interna'}">Auditoría interna</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('011-INC','r') == 1" :class="currentRouteName == 'Incidentes' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon"> <i v-tooltip.top="{content: 'INCIDENCIAS'}" style="inline-size: auto" class="fas fa-exclamation-circle fa-2x drop-custom"> </i></div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('011-INC-INC','r') == 1" :to="{name: 'Incidencias'}">Incidencias</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('011-INC-NOC','r') == 1" :to="{name: 'No Conformidades'}">No conformidades</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('011-INC-CAT','r') == 1" :to="{name: 'Categorías de las no conformidades'}">Categorías de las no conformidades</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('011-INC-INS','r') == 1" :to="{name: 'Incidencias de software'}">Incidencias de software</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('012-USU','r') == 1" :class="currentRouteName == 'Usuarios' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'USUARIOS'}" style="inline-size: auto" class="fas fa-users fa-2x drop-custom"> </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('012-USU-LIS','r') == 1" :to="{name: 'Lista de Usuarios'}">Usuarios</b-dropdown-item>
                        <b-dropdown-item v-if="checkPermissions('012-USU-PER','r') == 1" :to="{name: 'Perfiles'}">Perfiles</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('013-TAR','r') == 1" :class="currentRouteName == 'Tareas' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'TAREAS'}" style="inline-size: auto" class="far fa-calendar-alt fa-2x drop-custom"> </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('013-TAR-CAL','r') == 1" :to="{name: 'Calendario'}">Calendario</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('014-EMA','r') == 1" :class="currentRouteName == 'Correos' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'CORREOS'}" style="inline-size: auto" class="fas fa-envelope fa-2x drop-custom"> </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('014-EMA-CON','r') == 1" :to="{name: 'Control de correos'}">Control de correos</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" v-if="checkPermissions('015-CON','r') == 1" :class="currentRouteName == 'Control Horarios' ? 'dropdown-active':''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{content: 'CONTROL DE HORARIOS'}" style="inline-size: auto" class="far fa-clock fa-2x drop-custom"> </i>
                            </div>
                        </template>
                        <b-dropdown-item v-if="checkPermissions('015-CON-HOR','r') == 1" :to="{name: 'Control de Horario Productividad'}">Control horarios y productividad</b-dropdown-item>
                    </b-dropdown>
                </div>

            </CNavbarNav>

        </CCollapse>

        <CNavbarNav class="ml-auto">
            <ContentDatepicker class="mr-3 ml-2" />
            <ContentDropdown />
        </CNavbarNav>

    </CNavbar>
    <CSubheader class="px-3">

        <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
</div>
</template>

<script>
import ContentDropdown from './ContentDropdown'
import ContentDatepicker from './ContentDatepicker'

export default {
    name: 'ContentHeader',
    components: {
        ContentDropdown,
        ContentDatepicker
    },
    data() {
        return {
            collapsed: false,
            datoSession: {
                logo: JSON.parse(localStorage.settings).logo
            },

        }
    },
    created() {
        let primario = JSON.parse(localStorage.settings).primaryColor;
        let secundario = JSON.parse(localStorage.settings).secondaryColor;
        let accent = JSON.parse(localStorage.settings).accentColor;
        this.setThemeColors(primario, secundario, accent);
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        setThemeColors(primario, secundario, accent) {
            let style = document.documentElement.style;
            style.setProperty("--bg-primary-color", primario);
            style.setProperty("--bg-secondary-color", secundario);
            style.setProperty("--bg-accent-color", accent);
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.matched[1].name
        }
    },
    mounted() {}

}
</script>

<style lang="scss">
.bg-primario {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
}

.modal-primario .modal-header {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color);
}

.card-accent-primario {
    border-top: 2px solid var(--bg-primary-color) !important;
}

.dropdown-active {
    background: rgba(0, 0, 0, 0.25) !important;
    // filter: brightness(85%);
    border-radius: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bg-primary-color) !important;
}

.bg-nav {
    background-color: var(--bg-primary-color);
}

.table-custom thead th {
    color: var(--bg-secondary-color);
    background: var(--bg-primary-color);
    border-bottom: 0px;
}

/* ------------------------------- */

.bootstrap-datetimepicker-widget table td span.active,
.bootstrap-datetimepicker-widget table td i.active {
    background-color: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
    background-color: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

/* ------------------------------- */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
}

.nav-link {
    color: var(--bg-primary-color);
}

.nav-link:hover {
    color: var(--bg-primary-color);
}

/* ------------------------------- */

.vs__dropdown-option:hover {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.vs__dropdown-option--highlight {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */

a {
    text-decoration: none;
    background-color: transparent;
    color: var(--bg-primary-color);
}

a:hover {
    background-color: transparent;
    color: var(--bg-primary-color);
}

/* ------------------------------- */

.btn-custom {
    color: var(--bg-primary-color);
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    //F9F9F9
}

.btn-custom:hover {
    color: var(--bg-secondary-color);
    background-color: var(--bg-primary-color);
    border-color: var(--bg-primary-color);
}

.btn-custom:focus,
.btn-custom.focus {
    box-shadow: 0 0 0 0px var(--bg-primary-color);
}

.btn-custom.disabled,
.btn-custom:disabled {
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.btn-custom:active,
.btn-custom.active,
.show>.btn-custom.dropdown-toggle {
    background-color: var(--bg-primary-color);
    background-image: none;
    border-color: var(--bg-primary-color);
}

.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */
.btn-datepicker {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:hover {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:focus,
.btn-datepicker.focus {
    box-shadow: 0 0 0 0px var(--bg-secondary-color) !important;
}

.btn-datepicker.disabled,
.btn-datepicker:disabled {
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:active,
.btn-datepicker.active,
.show>.btn-datepicker.dropdown-toggle {
    background-color: var(--bg-secondary-color) !important;
    background-image: none;
    border-color: var(--bg-secondary-color) !important;
}

.input-custom {
    width: 45px;
    height: 30px;
    color: transparent;
    text-shadow: 0 0 0 var(--bg-primary-color);
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    text-align: center;
    font-weight: bolder;
}

/* ------------------------------- */

.icon-custom .ico {
    color: #cfcfcf;
}

.icon-custom:hover .ico {
    color: var(--bg-primary-color);
}

/*--------- pagination ----------*/

.page-item.active .page-link {
    background: var(--bg-primary-color) !important;
    border-color: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.page-link {
    color: var(--bg-primary-color) !important;
}

/* ------------------------------- */

.bg-accordion,
.bg-accordion:focus {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color);
    padding: 5px;
    outline: 0px !important;
}

.bg-accordion-transparent,
.bg-accordion-transparent:focus {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color);
    filter: opacity(70%);
    padding: 5px;
    outline: 0px !important;
}

.drop-custom {
    color: var(--bg-secondary-color);
}

.drop-custom:hover {
    color: var(--bg-secondary-color);
    filter: brightness(80%);
}

.dropdown-toggle::after {
    display: none
}

.show-arrow-down-dropdown-button>.dropdown-toggle-split::after {
    display: inline-block;
}

.dropdown-icon {
    font-size: 0.8rem
}

.dropdown-icon:hover {
    font-size: 0.83rem
}

/* .navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
} */
</style><style lang="scss">
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        // background: rgba(255, 255, 255, 0);
        // background: var(--bg-primary-color) !important;
        color: var(--bg-secondary-color) !important;
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        max-width: 250px;
    }

    &[x-placement^="top"] {

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 10px;

        .tooltip-arrow {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}
</style>
