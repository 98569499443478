<template>
<div>
    <b-button @click.prevent="abrirDatePicker()" variant="datepicker">
        <i class="fas fa-calendar-alt mr-1"></i>
        <strong>EJERCICIO</strong>
        <input type='text' class="input-custom" id='inputdatetimepicker' v-model="fecha" />
    </b-button>
</div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'ContentDatepicker',
    data() {
        return {
            fecha: JSON.parse(localStorage.settings).yExcs,
        }
    },
    methods: {
        abrirDatePicker() {
            $('#inputdatetimepicker').datetimepicker("show");
        },
        cambiarFecha() {
            this.$router.push({
                name: 'Panel de Control',
            }).catch(err => {});
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

        $('#inputdatetimepicker').datetimepicker({
            viewMode: 'years',
            format: 'YYYY',
            locale: 'pe'
        });

        $("#inputdatetimepicker").on("dp.change", (e) => {
            // if (confirm("Esta acción recargará la página, por lo que si ha rellenado algún formulario que aún no ha guardado, pulse en Cancelar, guarde los cambios y a continuación vuelva ha intentarlo.")) {
            let fecha = document.getElementById("inputdatetimepicker").value;

            let settings = JSON.parse(localStorage.settings);
            settings.yExcs = parseInt(fecha);
            localStorage.setItem('settings', JSON.stringify(settings));

            this.cambiarFecha();
        });

    }
}
</script>

<style scoped>
.b-icon {
    margin-right: 0.3rem;
}

.decor-none {
    text-decoration: none !important;
    color: #636F83;
    font-weight: bold;
}
</style>
